import React from 'react';
import { Parallax } from 'react-parallax';

const VisionIntroSection = () => {
  return (
    <div className="bg-primary/60">
      <Parallax
        bgClassName="object-cover bg-cover bg-center w-full opacity-80"
        bgImageSizes="cover"
        bgImage="/images/bg-vision.webp"
        strength={500}
      >
        <div className="min-h-screen flex justify-center items-center">
          <h2
            data-aos="fade-up"
            className="font-alternative font-bold text-6xl"
          >
            OUR VISION
          </h2>
        </div>
      </Parallax>
    </div>
  );
};

export default VisionIntroSection;
