import React from 'react';

const NotFoundView = () => {
  return (
    <div className="min-h-screen flex items-center justify-center">
      <h2 className="font-alternative text-primary text-4xl lg:text-6xl">
        404 - Page not found
      </h2>
    </div>
  );
};

export default NotFoundView;
