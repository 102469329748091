import React from 'react';
import Helmet from 'react-helmet';

import PatchworkIntroSection from '../components/PatchworkIntroSection';
import PatchworkWorkWrapper from '../components/PatchworkWorkWrapper';

const PatchworkView = () => {
  return (
    <>
      <Helmet>
        <title>Sufuri Patchwork</title>
      </Helmet>
      <PatchworkIntroSection />
      <PatchworkWorkWrapper />
    </>
  );
};

export default PatchworkView;
