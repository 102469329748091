import React from 'react';

const PatchworkIntroSection = () => {
  return (
    <div className="min-h-[50vh] lg:min-h-[80vh] flex justify-center items-center">
      <h2 data-aos="fade-up" className="font-alternative text-4xl lg:text-6xl">
        Patch<span className="text-primary">work</span>
      </h2>
    </div>
  );
};

export default PatchworkIntroSection;
