import React from 'react';
import 'animate.css';

const PageOverlay = () => {
  return (
    <div className="fixed overflow-none animate__animated animate__fadeIn animate__slow flex justify-center items-center top-0 left-0 w-full h-screen bg-black z-[1000]">
      <div>
        <img
          src="/images/logos/logo-gold.svg"
          className="w-36 animate__animated animate__pulse animate__infinite"
          alt="logo"
        />
      </div>
    </div>
  );
};

export default PageOverlay;
