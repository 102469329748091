import * as React from 'react';
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="-20 0 190 190"
    width={30}
    height={30}
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="m135.24 117.11-11 .26.34 29.7h-24.17l-1-29.89 10.83-.07-4.77-24.42-17.63-9.27 8.6-4.19c.94-4.57 4.84-6.52 4.84-9.37 0-4-6.56-4-6.56 1.33l-4.2-1C91 64.53 93 61 99.69 61c5.42 0 8 3 8 8.62 0 4.61-3.42 6.23-4 9.64l26.78 13.41 4.77 24.44Zm-61.32 30H26.47v-28.6l-11.7.28 4.5-26.31 27.17-13.25c.94-4.57 4.84-6.52 4.84-9.37 0-4-6.56-4-6.56 1.33l-4.2-1C41 64.53 43 61 49.69 61c5.42 0 8 3 8 8.62 0 4.61-3.42 6.23-4 9.64l26.78 13.43 4.77 24.42-11.17.26-.15 29.74Zm-2.5-67.81c.92-4.62 4.86-6.58 4.86-9.44 0-4-6.56-4-6.56 1.33l-4.2-1C66 64.53 68 61 74.69 61c5.42 0 8 3 8 8.62 0 4.91-3.87 6.43-4.11 10.32l-7.16-.64Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;
