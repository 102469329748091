import * as React from 'react';
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width={26}
    height={26}
    viewBox="-51.2 -51.2 614.4 614.4"
    {...props}
  >
    <path
      d="M342.956 68.603 256 137.103l-86.956-68.5L0 199.44l86.655 108.321 33.1-24.713v160.349h272.49v-160.34l33.092 24.713L512 199.44 342.956 68.603zm77.72 202.771-54.8-40.92v186.573h-219.75V230.454l-54.808 40.92-53.985-67.479 131.575-101.831L256 170.675l87.093-68.611L474.66 203.895l-53.984 67.479z"
      style={{
        fill: '#000',
      }}
    />
  </svg>
);
export default SvgComponent;
