import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Triangle } from 'react-loader-spinner';
import PatchworkItem from './PatchworkItem';

const PatchworkWorkWrapper = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [patchworkList, setPatchworkList] = useState([]);

  useEffect(() => {
    const getTeams = async () => {
      try {
        setIsLoading(true);
        const res = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/patchworks?sort[0]=id%3Adesc&populate=*`
        );
        setPatchworkList(res.data.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    getTeams();
  }, []);

  return (
    <div className="min-h-screen">
      <div className="py-20 flex flex-col justify-center">
        <div className="py-3 container mx-auto w-full px-2 sm:px-0">
          <div className="relative  antialiased  font-semibold">
            {isLoading ? (
              <div className="flex items-center justify-center">
                <Triangle
                  height="80"
                  width="80"
                  color="#eebe68"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <>
                <div className="hidden md:block  after:bg-primary w-[1.5px] bg-primary/75 absolute h-full left-1/2 transform -translate-x-1/2" />
                {patchworkList.map((patchwork, index) => (
                  <PatchworkItem
                    key={patchwork.id}
                    patchwork={patchwork}
                    reverseOrder={index % 2 === 0 ? false : true}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatchworkWorkWrapper;
