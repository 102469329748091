import React from 'react';
import Helmet from 'react-helmet';
import VisionIntroSection from '../components/VisionIntroSection';
import { VisionStorySection } from '../components/VisionStorySection';
import VisionTeamSection from '../components/VisionTeamSection';

const VisionView = () => {
  return (
    <>
      <Helmet>
        <title>Sufuri Vision</title>
      </Helmet>
      <VisionIntroSection />
      <VisionStorySection />
      <VisionTeamSection />
    </>
  );
};

export default VisionView;
