import React from 'react';
import { Parallax } from 'react-parallax';

export const VisionStorySection = () => {
  return (
    <Parallax
      blur={1}
      bgClassName="object-cover w-full"
      strength={500}
      bgImageSizes="cover"
      bgImage="/images/potraits/bg-story.png"
    >
      <div className="min-h-screen relative py-14">
        <h2
          data-aos="zoom-in"
          className="text-4xl lg:text-8xl relative lg:absolute lg:left-1/2 lg:top-[20%] uppercase font-alternative my-2 lg:my-0 text-center lg:text-justify text-primary tracking-widest z-20 font-bold"
        >
          Our story
        </h2>
        <div className="bg-gray-200 flex relative justify-center items-center gap-2 p-12 text-black w-full lg:w-[800px] min-h-[500px]">
          <div className="w-full lg:w-[650px]">
            <h4 className="text-9xl rotate-90 top-1/2 left-1/2 absolute  font-bold font-alternative text-primary/20">
              -01
            </h4>
            <div className="border border-primary"></div>
            <p data-aos="fade-up" className="pt-7 pr-3 tracking-wide z-10">
              Founded in 2022, Project Sufuri is a revolutionary initiative
              centered around the promotion and development of Rwanda’s local
              fashion industry. Guided by innovative approaches to streamline
              process efficiencies for improved customer engagement and apply
              informed solutions to business challenges, Project Sufuri will
              promote a 100% "Made in Rwanda" approach at every level of garment
              design and production.
            </p>
          </div>
        </div>
        <div className="block lg:flex justify-end">
          <div className="bg-gray-200 flex items-center justify-center  p-12 text-black w-full lg:w-[800px] relative min-h-[500px] mt-14 lg:mt-44">
            <div className="w-full lg:w-[650px]">
              <h4 className="text-9xl rotate-90 top-1/2 left-1/2 absolute  font-bold font-alternative text-primary/20">
                -02
              </h4>
              <div className="border border-primary"></div>
              <p data-aos="fade-up" className="py-3 tracking-wide z-10">
                Project Sufuri aims to establish robust partnerships with
                designers, tailors, sewing cooperatives, fabric retailers and
                manufacturers, event organizers as well as government and civil
                society actors to better understand the creative and industrial
                gaps and challenges facing Rwanda’s fashion industry in order to
                effectively address them.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Parallax>
  );
};
