import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Triangle } from 'react-loader-spinner';
import TeamMember from './TeamMember';

const VisionTeamSection = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [teamList, setTeamList] = useState([]);

  useEffect(() => {
    const getTeams = async () => {
      try {
        setIsLoading(true);
        const res = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/teams?sort[0]=id%3Aasc&populate=*`
        );
        setTeamList(res.data.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    getTeams();
  }, []);

  return (
    <div className="min-h-screen py-28 bg-gray-900/40 ">
      <div className="container mx-auto px-3">
        <h2 className="font-alternative text-4xl" data-aos="fade-up">
          our team
        </h2>
        {isLoading ? (
          <div>
            <div className="flex items-center justify-center">
              <Triangle
                height="80"
                width="80"
                color="#eebe68"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          </div>
        ) : (
          <div className="mt-20 grid grid-cols-1  lg:grid-cols-3 gap-24">
            {/* first column */}
            {teamList[0] ? <TeamMember member={teamList[0]} /> : <div></div>}
            <div className="hidden lg:flex items-center justify-center">
              <img
                src="images/logos/sufuri-tree-logo.png"
                className="hover:animate-pulse cursor-pointer"
                alt="logo"
              />
            </div>
            {/* second column */}
            {teamList[1] ? (
              <TeamMember member={teamList[1]} isTopLayout />
            ) : (
              <div></div>
            )}

            {/* third column */}

            {teamList.map((member, index) =>
              index > 1 ? (
                <TeamMember
                  member={member}
                  isTopLayout={index % 2 === 0 ? false : true}
                />
              ) : (
                <></>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default VisionTeamSection;
