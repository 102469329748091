/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { HiMenuAlt3 } from 'react-icons/hi';
import $ from 'jquery';
import gsap, { Power2, Expo } from 'gsap';
import { Link, useLocation } from 'react-router-dom';

const AppNav = () => {
  const location = useLocation();

  useEffect(() => {
    $('html').removeClass('tt-no-scroll');
    $('body').removeClass('tt-ol-menu-open').removeClass('tt-ol-menu-active');
  }, [location]);

  useEffect(() => {
    // ==================================================
    // Overlay menu
    // ==================================================

    // Add class "tt-header-fixed-on" to <body> if "tt-header-fixed" enabled.
    if ($('#tt-header').hasClass('tt-header-fixed')) {
      $('body').addClass('tt-header-fixed-on');
    }

    // On menu toggle button click
    // ============================
    var $olMenuToggleBtn = $(
      '.tt-ol-menu-toggle-btn-text, .tt-ol-menu-toggle-btn'
    );

    $olMenuToggleBtn.on('click', function () {
      $('html').toggleClass('tt-no-scroll');
      $('body').toggleClass('tt-ol-menu-open').addClass('tt-ol-menu-active');
      if ($('body').hasClass('tt-ol-menu-open')) {
        // Menu step in animations
        $('body').addClass('olm-toggle-no-click'); // Disable toggle button click until the animations last.

        var tl_olMenuIn = gsap.timeline({
          onComplete: function () {
            $('body').removeClass('olm-toggle-no-click');
          },
        });

        tl_olMenuIn.to('.tt-overlay-menu', { duration: 0.4, autoAlpha: 1 });
        tl_olMenuIn.from('.tt-ol-menu-list > li', {
          duration: 0.4,
          y: 80,
          autoAlpha: 0,
          stagger: 0.05,
          ease: Power2.easeOut,
          clearProps: 'all',
        });
        if ($('.tt-ol-menu-social').length) {
          tl_olMenuIn.to(
            '.tt-ol-menu-social',
            { duration: 0.4, autoAlpha: 1 },
            '-=0.4'
          );
          tl_olMenuIn.from(
            '.tt-ol-menu-social > li',
            {
              duration: 0.4,
              y: 80,
              autoAlpha: 0,
              stagger: 0.05,
              ease: Power2.easeOut,
              clearProps: 'all',
            },
            '-=0.4'
          );
        }
        if ($('.tt-ol-menu-ghost').length) {
          tl_olMenuIn.from(
            '.tt-ol-menu-ghost',
            {
              duration: 0.4,
              y: 80,
              autoAlpha: 0,
              ease: Power2.easeOut,
              clearProps: 'all',
            },
            '-=0.4'
          );
        }

        // On menu link click
        $('.tt-overlay-menu a, .tt-logo a')
          .not('[target="_blank"]') // omit from selection
          .not('[href^="#"]') // omit from selection
          .not('[href^="mailto"]') // omit from selection
          .not('[href^="tel"]') // omit from selection
          .on('click', function () {
            gsap.set('#content-wrap, .ttgr-cat-nav', { autoAlpha: 0 }); // Hide before timeline
            var tl_olMenuClick = gsap.timeline();
            tl_olMenuClick.to('.tt-ol-menu-list > li', {
              duration: 0.4,
              y: -80,
              autoAlpha: 0,
              stagger: 0.05,
              ease: Power2.easeIn,
            });
            if ($('.tt-ol-menu-social').length) {
              tl_olMenuClick.to(
                '.tt-ol-menu-social > li',
                {
                  duration: 0.4,
                  y: -80,
                  autoAlpha: 0,
                  stagger: 0.05,
                  ease: Power2.easeIn,
                },
                '-=0.4'
              );
              tl_olMenuClick.to(
                '.tt-ol-menu-social',
                { duration: 0.4, autoAlpha: 0 },
                '-=0.4'
              );
            }
            if ($('.tt-ol-menu-ghost').length) {
              tl_olMenuClick.to(
                '.tt-ol-menu-ghost',
                { duration: 0.4, y: -40, autoAlpha: 0, ease: Power2.easeIn },
                '-=0.4'
              );
            }
          });

        // Hide sliding sidebar
        if ($('.tt-sliding-sidebar-wrap').length) {
          gsap.to('.tt-sliding-sidebar-trigger', {
            duration: 1,
            autoAlpha: 0,
            ease: Expo.easeOut,
          });
        }

        // Move "tt-header" out of "scroll-container" (if Smooth Scroll is enabled and "tt-header-fixed" is NOT enabled)
        // Expl: Since Smooth Scrollbar doesn't support element fixed position inside "scroll-container" move the "tt-header" out of it
        if ($('body').hasClass('tt-smooth-scroll')) {
          if (!$('#tt-header').hasClass('tt-header-fixed')) {
            $('#scroll-container').before($('#tt-header'));
          }
        }

        // Move "tt-ol-menu-social" out of "tt-header"
        // Expl: Due to the conflict with fixed position and ancestor transitions
        if ($('.tt-ol-menu-social').length) {
          function ttOlMenuSocialResize() {
            if (window.matchMedia('(min-width: 768px)').matches) {
              $('#tt-header').before($('.tt-ol-menu-social'));
            } else {
              $('.tt-ol-menu-list').after($('.tt-ol-menu-social'));
            }
          }
          ttOlMenuSocialResize();

          $(window).resize(function () {
            ttOlMenuSocialResize();
          });
        }
      } else {
        // Menu step out animations
        $('body').addClass('olm-toggle-no-click'); // Disable toggle button click until the animations last.

        var tl_olMenuOut = gsap.timeline({
          onComplete: function () {
            $('body').removeClass('olm-toggle-no-click');
          },
        });
        tl_olMenuOut.to('.tt-ol-menu-list > li', {
          duration: 0.4,
          y: -80,
          autoAlpha: 0,
          stagger: 0.05,
          ease: Power2.easeIn,
        });
        if ($('.tt-ol-menu-social').length) {
          tl_olMenuOut.to(
            '.tt-ol-menu-social > li',
            {
              duration: 0.4,
              y: -80,
              autoAlpha: 0,
              stagger: 0.05,
              ease: Power2.easeIn,
            },
            '-=0.4'
          );
          tl_olMenuOut.to(
            '.tt-ol-menu-social',
            { duration: 0.4, autoAlpha: 0, clearProps: 'all' },
            '-=0.4'
          );
        }
        if ($('.tt-ol-menu-ghost').length) {
          tl_olMenuOut.to(
            '.tt-ol-menu-ghost',
            { duration: 0.4, y: -60, autoAlpha: 0, ease: Power2.easeIn },
            '-=0.4'
          );
        }
        tl_olMenuOut.to(
          '.tt-overlay-menu',
          { duration: 0.4, autoAlpha: 0, clearProps: 'all' },
          '+=0.2'
        );
        tl_olMenuOut.set(
          '.tt-ol-menu-list > li, .tt-ol-menu-social > li, .tt-ol-menu-ghost',
          { clearProps: 'all' }
        ); // clearProps only

        // Show sliding sidebar
        if ($('.tt-sliding-sidebar-wrap').length) {
          gsap.to(
            '.tt-sliding-sidebar-trigger',
            {
              duration: 1,
              autoAlpha: 1,
              ease: Expo.easeOut,
              clearProps: 'all',
            },
            '-=0.3'
          );
        }

        // Close open submenus
        setTimeout(function () {
          $('.tt-ol-submenu').slideUp(350);
          $('.tt-ol-submenu-trigger').removeClass('tt-ol-submenu-open');
          $('body').removeClass('tt-ol-menu-active');

          // Move "tt-header" back to the "scroll-container" (if Smooth Scroll is enabled and "tt-header-fixed" is NOT enabled)
          if ($('body').hasClass('tt-smooth-scroll')) {
            if (!$('#tt-header').hasClass('tt-header-fixed')) {
              $('#content-wrap').before($('#tt-header'));
            }
          }

          // Move "tt-ol-menu-social" back to the "tt-overlay-menu"
          if ($('.tt-ol-menu-social').length) {
            $('.tt-ol-menu-list').after($('.tt-ol-menu-social'));
          }
        }, 900);
      }

      return false;
    });

    // Menu list hover
    $('.tt-ol-menu-list')
      .on('mouseenter', function () {
        $(this).addClass('tt-ol-menu-hover');
      })
      .on('mouseleave', function () {
        $(this).removeClass('tt-ol-menu-hover');
      });

    // Open submenu if link href contains #
    $('.tt-ol-submenu-trigger > a').on('click', function () {
      if ($(this).is('[href^="#"]')) {
        var $this = $(this).parent();
        if ($this.hasClass('tt-ol-submenu-open')) {
          $this.removeClass('tt-ol-submenu-open');
          $this.next().slideUp(350);
        } else {
          $this
            .parent()
            .parent()
            .find('.tt-ol-submenu')
            .prev()
            .removeClass('tt-ol-submenu-open');
          $this.parent().parent().find('.tt-ol-submenu').slideUp(350);
          $this.toggleClass('tt-ol-submenu-open');
          $this.next().slideToggle(350);
        }
      }
      return false;
    });

    // Open submenu on caret click
    $('.tt-ol-submenu-caret-wrap').on('click', function () {
      var $this = $(this).parent();
      if ($this.hasClass('tt-ol-submenu-open')) {
        $this.removeClass('tt-ol-submenu-open');
        $this.next().slideUp(350);
      } else {
        $this
          .parent()
          .parent()
          .find('.tt-ol-submenu')
          .prev()
          .removeClass('tt-ol-submenu-open');
        $this.parent().parent().find('.tt-ol-submenu').slideUp(350);
        $this.toggleClass('tt-ol-submenu-open');
        $this.next().slideToggle(350);
      }
    });
  }, []);

  return (
    <header id="tt-header" className="tt-header-fixed">
      <div className="tt-header-inner">
        <div className="tt-header-col">
          {/* Begin logo 
							================ */}
          <div className="tt-logo">
            <Link to="/">
              <img
                src="/images/logos/sufuri-tree-logo.png"
                className="tt-logo-light hover-hop m-0 lg:-mt-9"
                alt="Logo"
              />
            </Link>
          </div>
          {/* End logo */}
        </div>
        {/* /.tt-header-col */}
        <div className="tt-header-col">
          {/* Begin overlay menu toggle button */}
          <div id="tt-ol-menu-toggle-btn-wrap">
            <div className="tt-ol-menu-toggle-btn-text-wrap hide-cursor ">
              <div className="tt-ol-menu-toggle-btn-text font-sans  uppercase">
                <span className="text-menu text-primary" data-hover="Open">
                  Menu
                </span>
                <span className="text-close text-primary">Close</span>
              </div>
              {/* /.tt-ol-menu-toggle-btn-text */}
            </div>
            {/* /.tt-ol-menu-toggle-btn-text-wrap */}
            <div className="tt-ol-menu-toggle-btn-holder">
              <a href="#" className="tt-ol-menu-toggle-btn magnetic-item">
                <span />
              </a>
            </div>
            {/* /.tt-ol-menu-toggle-btn-holder */}
          </div>
          {/* End overlay menu toggle button */}
          {/* Begin overlay menu 

							*/}
          <nav className="tt-overlay-menu tt-ol-menu-count">
            <div className="tt-ol-menu-holder tt-menu-wrapper">
              <div className="tt-ol-menu-inner tt-wrap">
                <div className="tt-ol-menu-content tt-row tt-justify-content-end margin-top-30">
                  {/* Begin menu list */}
                  <ul className="tt-ol-menu-list font-alternative  uppercase">
                    <li className="!font-alternative  ">
                      <Link to="/" className="!text-primary">
                        Home
                      </Link>
                    </li>
                    <li className="!font-alternative  ">
                      <Link to="/vision" className="!text-primary">
                        Our Vision
                      </Link>
                    </li>
                    <li className="!font-alternative">
                      <Link to="/tailormate" className="!text-primary">
                        TailorMate
                      </Link>
                    </li>
                    <li className="!font-alternative  ">
                      <Link to="/patchwork" className="!text-primary">
                        Patchwork
                      </Link>
                    </li>
                  </ul>
                  {/* End menu list */}
                  {/* End overlay menu social links */}
                </div>
                {/* /.tt-ol-menu-content */}
              </div>
              {/* /.tt-ol-menu-inner */}
            </div>
            {/* /.tt-ol-menu-holder */}
          </nav>
          {/* End overlay menu */}
        </div>
        {/* /.header-col */}
      </div>
      {/* /.header-inner */}
    </header>
  );
};

export default AppNav;
