/* eslint-disable import/no-anonymous-default-export */
export default {
  english: {
    introcontent:
      'Tailormate is a unique platform that offers customers the opportunity to order custom-made clothing tailored specifically to their preferences and measurements. Our platform connects customers with talented designers who are dedicated to creating unique, high-quality pieces that fit perfectly and look stunning.',
    selectLanguage: ' select preferred language',
    name: 'Your Name',
    email: 'Your Email',
    language: 'Language',
    phone: 'Your phone number',
    clothType: 'Type of clothing',
    clothSubType: 'cloth sub category',
    measurements: 'measurements',
    measurementsDescription: 'Do you know your measurements?',
    'measurements-answer-1': 'no',
    'measurements-answer-2': 'yes',
    fabrics: 'fabrics',
    fabricColor: 'fabric color',
    dueDate: 'Due Date',
    chooseDate: 'choose Date',
    budget: 'budget',
    budgetDescription:
      "what is the maximum amount you'd like to spend on this item?",
    moreInfo: 'additional design preferences',
    otherFabrics: 'Other Fabric',
    measurementsList: {
      bust: 'bust',
      waist: 'waist',
      hip: 'hip',
      inseam: 'inseam',
      shoulder: 'shoulder',
      arm: 'arm',
      thigh: 'thigh',
      calf: 'calf',
      neck: 'neck',
    },
    moreInfoSubText:
      'Include any additional comments on the design for your TailorMate to note.',
    submit: 'submit',
    flexible: 'Flexible',
  },
  swahili: {
    introcontent:
      'Tailormate ni jukwaa la kipekee linalotoa wateja fursa ya kuagiza nguo zilizotengenezwa kwa kibinafsi kulingana na mapendeleo na vipimo vyao. Jukwaa letu linawakutanisha wateja na waundaji wenye vipaji ambao wanajitolea kuunda vipande vyenye ubora wa hali ya juu ambavyo vinakaa vizuri na kuonekana vizuri.',
    selectLanguage: 'Chagua lugha unayopendelea',
    name: 'Jina lako',
    email: 'Barua pepe yako',
    language: 'Lugha',
    phone: 'Nambari yako ya simu',
    clothType: 'Aina ya nguo',
    clothSubType: 'Kategoria ya nguo',
    measurements: 'Vipimo',
    measurementsDescription: 'Je! Unajua vipimo vyako?',
    'measurements-answer-1': 'hapana',
    'measurements-answer-2': 'ndio',
    fabrics: 'Vifaa vya nguo',
    fabricColor: 'Rangi ya kitambaa',
    dueDate: 'Tarehe ya mwisho',
    chooseDate: 'Chagua tarehe',
    budget: 'Bajeti',
    budgetDescription:
      'Je! Ni kiwango gani cha juu unachotaka kutumia kwenye hii bidhaa?',
    moreInfo: 'Mapendekezo ya kubuni ziada',
    otherFabrics: 'Kitambaa kingine',
    measurementsList: {
      bust: 'kifua',
      waist: 'kiuno',
      hip: 'paja',
      inseam: 'urefu wa ndani wa paja',
      shoulder: 'paja la juu',
      arm: 'mkono',
      thigh: 'paja',
      calf: 'paja la chini',
      neck: 'shingo',
    },
    moreInfoSubText:
      'Jumuisha maoni yoyote ya ziada kuhusu muundo ambao TailorMate yako anapaswa kuzingatia.',
    submit: 'tuma',
    flexible: 'Kubadilika',
  },
  french: {
    introcontent:
      "Tailormate est une plateforme unique qui offre aux clients l'opportunité de commander des vêtements sur mesure confectionnés spécifiquement selon leurs préférences et leurs mesures. Notre plateforme met en relation les clients avec des designers talentueux qui sont dévoués à la création de pièces uniques de haute qualité qui s'ajustent parfaitement et ont un rendu remarquable.",
    selectLanguage: 'sélectionner la langue préférée',
    name: 'votre nom',
    email: 'Votre e-mail',
    language: 'Langue',
    phone: 'Votre numéro de téléphone',
    clothType: 'Type de vêtement',
    clothSubType: 'Sous catégorie de vêtement',
    measurements: 'Mesures',
    measurementsDescription: 'Connaissez-vous vos mesures ?',
    'measurements-answer-1': 'non',
    'measurements-answer-2': 'oui',
    fabrics: 'Tissus',
    fabricColor: 'Couleur du tissu',
    dueDate: "Date d'échéance",
    chooseDate: 'Choisissez la date',
    budget: 'Budget',
    budgetDescription:
      'Quel est le montant maximal que vous souhaitez dépenser pour cet article ?',
    moreInfo: 'Préférences de conception supplémentaires',
    otherFabrics: 'Autre tissu',
    measurementsList: {
      bust: 'tour de poitrine',
      waist: 'tour de taille',
      hip: 'tour de hanches',
      inseam: "longueur d'entrejambe",
      shoulder: "largeur d'épaules",
      arm: 'longueur de bras',
      thigh: 'tour de cuisse',
      calf: 'tour de mollet',
      neck: 'tour de cou',
    },
    moreInfoSubText:
      'Incluez tout commentaire supplémentaire sur la conception que votre TailorMate doit noter.',
    submit: 'Soumettre',
    flexible: 'Flexible',
  },
  kinyarwanda: {
    introcontent:
      "Tailormate ni urubuga rwihariye ruha abakiriya amahirwe yo gutumiza imyenda ikozwe neza ijyanye nibyo bakunda n'ibipimo byabo. Ihuriro ryacu rihuza abakiriya na abashushanya imideri bafite impano yo gukora ibice byihariye, byujuje ubuziranenge bihuye neza kandi bisa neza.",
    selectLanguage: ' hitamo ururimi ukunda',
    name: 'Izina ryawe',
    email: 'Imeli yawe',
    language: 'ururimi',
    phone: 'Nomero yawe ya telefoni',
    clothType: "UBWOKO BW'IMYambarire",
    clothSubType: 'IMYENDA SUB CATEGORY',
    measurements: 'ibipimo',
    measurementsDescription: 'Waba uzi ibipimo byawe?',
    'measurements-answer-1': 'Oya',
    'measurements-answer-2': 'Yego',
    measurementsList: {
      bust: 'bust',
      waist: 'ikibuno',
      hip: 'hip',
      inseam: 'inseam',
      shoulder: 'igitugu',
      arm: 'ukuboko',
      thigh: 'ikibero',
      calf: 'calf',
      neck: 'ijosi',
    },
    fabrics: 'ibitambara',
    fabricColor: "ibara ry'imyenda",
    otherFabrics: 'Ibindi ibitambara',
    dueDate: 'itariki ntarengwa',
    chooseDate: 'Hitamo itariki',
    budget: 'bije',
    budgetDescription:
      'ni ikihe gipimo ntarengwa wifuza gukoresha kuri iki kintu ?',
    moreInfo: 'ibyifuzo byinyongera',
    moreInfoSubText:
      'Shyiramo ibisobanuro byinyongera kubishushanyo bya TailorMate yawe kugirango wandike.',
    submit: 'Ohereza',
    flexible: 'nta tariki ntarengwa',
  },
};
