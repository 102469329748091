import * as React from 'react';
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -0.8 60.998 60.998"
    width={26}
    height={26}
    {...props}
  >
    <g data-name="Group 112">
      <path
        fill="#231f20"
        d="M26.354 59.13a.988.988 0 0 1-.215-.023l-8.7-1.9a.838.838 0 0 1-.138-.041c-2.228-.837-2.228-3.765-2.228-4.727l.559-36.511a1 1 0 0 1 1-.985h.015a1 1 0 0 1 .985 1.015l-.559 36.5c0 1.622.287 2.549.878 2.818l7.66 1.677 3.916-16.132a1 1 0 1 1 1.943.472l-4.146 17.082a1 1 0 0 1-.97.755Z"
        data-name="Path 83"
      />
    </g>
    <g data-name="Group 113">
      <path
        fill="#231f20"
        d="M34.579 59.398a1.014 1.014 0 0 1-.488-.126 1 1 0 0 1-.486-.645l-4.079-17.35a1 1 0 1 1 1.947-.458l3.828 16.284 7.66-2.558c.749-.282.9-.577.9-1.844l-.492-36.75a1 1 0 0 1 .986-1.013h.014a1 1 0 0 1 1 .987l.492 36.763c0 1.412-.173 2.968-2.228 3.742l-8.735 2.917a1.01 1.01 0 0 1-.319.051Z"
        data-name="Path 84"
      />
    </g>
    <g data-name="Group 114">
      <path
        fill="#231f20"
        d="M51.711 48.802c-1.244 0-2.088-.865-2.473-2.59l-5.85-30.083a1 1 0 1 1 1.963-.382l5.845 30.057c.242 1.086.424 1.048.856.95l6.693-.98a1.439 1.439 0 0 0 .258-.082 6 6 0 0 0-.145-.839L52.137 5.476c-.979-.888-7.332-2.722-8.895-2.87l-5.545-.528-6.22 26.385a1 1 0 0 1-1.947 0L23.31 2.078l-5.545.528c-1.564.148-7.922 1.983-8.9 2.871L2.154 44.805A6.671 6.671 0 0 0 2 45.69a1.914 1.914 0 0 0 .33.1l6.55.954c.506.11.684.148.932-.964l5.84-30.03a1 1 0 1 1 1.963.382L11.77 46.188c-.458 2.059-1.547 2.9-3.251 2.522l-6.55-.954a2.246 2.246 0 0 1-1.866-1.362 3.672 3.672 0 0 1 .089-1.971L6.925 4.965C7.46 2.547 17.473.627 17.572.618l6.413-.611a1 1 0 0 1 1.068.766l5.446 23.105L35.946.773a1 1 0 0 1 1.068-.766l6.413.611c.1.009 10.111 1.929 10.637 4.3l6.752 39.555a3.555 3.555 0 0 1 .079 1.923 2.2 2.2 0 0 1-1.794 1.349l-6.692.98a3.225 3.225 0 0 1-.698.077Z"
        data-name="Path 85"
      />
    </g>
    <g data-name="Group 115">
      <path
        fill="#231f20"
        d="M31.491 34.963a1.059 1.059 0 1 1-1.06-1.059 1.06 1.06 0 0 1 1.06 1.059Z"
        data-name="Path 86"
      />
    </g>
    <g data-name="Group 116">
      <path
        fill="#231f20"
        d="M30.605 2.253a24.969 24.969 0 0 1-7.443-1.076.5.5 0 1 1 .323-.946 24.583 24.583 0 0 0 14.042 0 .5.5 0 1 1 .3.954 24.161 24.161 0 0 1-7.222 1.068Z"
        data-name="Path 87"
      />
    </g>
    <g data-name="Group 117">
      <path
        fill="#231f20"
        d="M40.578 19.159h-3.375a.5.5 0 0 1 0-1h3.375a.5.5 0 0 1 0 1Z"
        data-name="Path 88"
      />
    </g>
  </svg>
);
export default SvgComponent;
