import React from 'react';
import { Link } from 'react-router-dom';

const HomeMissionSection = () => {
  return (
    <div className="min-h-screen">
      <div className="p-5 w-full lg:w-[550px] space-y-3 text-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <Link
          to="/vision"
          className="font-alternative text-3xl lg:text-6xl font-semibold hover:text-primary transition-all"
        >
          our vision
        </Link>
      </div>
    </div>
  );
};

export default HomeMissionSection;
