import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import AppNav from './components/AppNav';
import AppFooter from './components/AppFooter';

import HomeView from './views/HomeView';
import PatchworkView from './views/PatchworkView';
import VisionView from './views/VisionView';
import PatchworkSingle from './views/PatchworkSingle';
import NotFoundView from './views/NotFoundView';
import PageOverlay from './components/PageOverlay';
import TailorMate from './views/TailorMate';

const WebMailRedirect = () => {
  useEffect(() => {
    window.location.replace('https://usm102.siteground.biz/webmail/mail');
  }, []);

  return <div className="text-center py-3 font-mono">Redirecting....</div>;
};

function App() {
  const location = useLocation();
  const [loadingOverlay, setLoadingOverlay] = useState(true);
  useEffect(() => {
    setLoadingOverlay(true);
    setTimeout(() => setLoadingOverlay(false), 3200);
  }, [location]);
  useEffect(() => {
    AOS.init({
      delay: 200, // values from 0 to 3000, with step 50ms
      duration: 900, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
    });
  }, []);
  return (
    <>
      {loadingOverlay ? (
        <PageOverlay />
      ) : (
        <>
          <AppNav />
          <Routes>
            <Route path="*" element={<NotFoundView />} />
            <Route path="/" element={<HomeView />} />
            <Route path="/vision" element={<VisionView />} />
            <Route path="/patchwork" element={<PatchworkView />} />
            <Route path="/patchwork/:id" element={<PatchworkSingle />} />
            <Route path="/tailormate" element={<TailorMate />} />
            {/* <Route path="/events" element={<EventsView />} /> */}
            {/* <Route path="/event/:id" element={<EventView />} /> */}
            <Route path="/webmail" element={<WebMailRedirect />} />
          </Routes>
          <AppFooter />
        </>
      )}
    </>
  );
}

export default App;
