import * as React from 'react';
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width={26}
    height={26}
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      d="M379.907 332.1c-5.178-13.554-10.494-25.696-14.853-36.577-4.374-10.881-7.737-20.51-9.278-28.824-.988-5.262-1.413-10.175-1.413-14.914 0-8.656 1.428-16.773 3.782-25.202 3.515-12.635 9.271-25.916 14.761-41.543 5.467-15.619 10.546-33.607 12.24-55.408l.175-2.331-.736-2.225c-14.321-43.403-16.432-79.897-16.424-97.406 0-3.706.091-6.553.182-8.436.038-.942.083-1.648.114-2.088l.038-.486.008-.084v-.008l1.002-11.086L329.336 0l-72.273 109.564L182.55.015l-40.055 5.468 1.01 11.086s.015.228.053.707c.091 1.435.288 5.019.288 10.395.008 17.51-2.111 54.003-16.432 97.406l-.728 2.225.182 2.331c2.24 29.082 10.532 51.338 17.715 70.321 3.599 9.507 6.918 18.201 9.272 26.63 2.369 8.429 3.789 16.546 3.789 25.202 0 4.738-.425 9.651-1.404 14.914-1.549 8.314-4.914 17.943-9.287 28.824-13.053 32.697-34.922 76.396-34.906 136.763 0 21.52 2.764 45.119 9.544 71.005l2.278 8.709h264.269l2.278-8.709c6.773-25.886 9.544-49.485 9.537-71.005.016-40.23-9.697-73.094-20.046-100.187zm-9.909 156.589H142.01c-4.723-20.578-6.652-39.257-6.652-56.403.016-36.47 8.687-66.062 18.528-91.879 4.913-12.901 10.145-24.83 14.708-36.22 4.556-11.39 8.49-22.233 10.555-33.198 1.245-6.659 1.815-13.053 1.815-19.203 0-11.268-1.898-21.663-4.655-31.489-4.16-14.761-10.106-28.33-15.202-42.955-4.92-14.071-9.135-29.143-10.79-47.375 14.602-45.377 16.842-83.283 16.849-102.296 0-.714-.015-1.359-.022-2.02l4.252-.577 71.794 105.532-15.202 23.045 19.461 12.84 93.269-141.403 4.13.562c0 .661-.008 1.306-.008 2.02.008 19.014 2.248 56.904 16.835 102.266-2.225 24.542-9.104 43.388-16.06 61.764-3.568 9.438-7.16 18.755-9.924 28.596-2.756 9.826-4.647 20.221-4.647 31.489 0 6.15.57 12.544 1.815 19.203 2.065 10.965 6.006 21.808 10.554 33.198 13.722 34.124 33.213 73.396 33.229 128.099 0 17.147-1.921 35.826-6.644 56.404z"
      style={{
        fill: '#000',
      }}
    />
  </svg>
);
export default SvgComponent;
