import * as React from 'react';
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width={26}
    height={26}
    viewBox="0 0 48 48"
    {...props}
  >
    <g
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4}
    >
      <path d="m16 12 2-8h12l2 8v12l4 17-12 3-12-3 4-17V12ZM24 44V16" />
    </g>
  </svg>
);
export default SvgComponent;
