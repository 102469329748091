import React from 'react';
import { Helmet } from 'react-helmet';
import { Parallax } from 'react-parallax';
import TailorMateWrapper from '../components/TailorMateWrapper';

const TailorMate = () => {
  return (
    <div>
      {' '}
      <Helmet>
        <title>Tailormate</title>
      </Helmet>
      <Parallax
        bgClassName="object-cover bg-cover bg-bottom w-full"
        bgImageSizes="cover"
        bgImage="/images/potraits/tailormate.jpg"
        strength={500}
      >
        <div className="min-h-screen   flex justify-center items-center">
          <div className="text-center space-y-2">
            <h2
              data-aos="fade-up"
              className="font-alternative font-bold text-3xl md:text-6xl"
            >
              Tailor<span className="text-primary">Mate</span>
            </h2>
            <p className="font-sans">Made-to-measure, tailored for you!</p>
          </div>
        </div>
      </Parallax>
      <TailorMateWrapper />
    </div>
  );
};

export default TailorMate;
