/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { IoLogoTiktok } from 'react-icons/io5';
import { FiYoutube, FiTwitter, FiInstagram } from 'react-icons/fi';
import ContactModal from './ContactModal';

const AppFooter = () => {
  return (
    <div className="bg-black min-h-screen w-full flex items-center py-10 text-primary">
      <div className="container mx-auto">
        <div className="flex justify-center">
          <img
            data-aos="zoom-in"
            alt="logo"
            className="w-64"
            src="/images/logos/logo-2-04.svg"
          />
        </div>
        <div className="text-center py-20">
          <h4
            data-aos="zoom-in"
            className="font-alternative text-2xl lg:text-5xl"
          >
            — Let's take our relationship to the next level
          </h4>
        </div>
        <div className="grid mt-10 lg:mt-44 justify-items-center lg:justify-items-stretch grid-cols-1 lg:grid-cols-2 gap-4">
          <div>
            <div className="space-y-14 flex justify-center flex-col items-center lg:block">
              <div className="flex space-x-7 text-xl">
                <a
                  href="https://twitter.com/ProjectSufuri"
                  target="__blank"
                  className="hover:text-primary/60 transition-all"
                >
                  <FiTwitter />
                </a>
                <a
                  href="https://www.instagram.com/project.sufuri"
                  target="__blank"
                  className="hover:text-primary/60 transition-all"
                >
                  <FiInstagram />
                </a>
                <a
                  href="https://www.tiktok.com/@projectsufuri"
                  target="__blank"
                  className="hover:text-primary/60 transition-all"
                >
                  <IoLogoTiktok />
                </a>
                <a
                  href="https://www.youtube.com/@projectsufuri"
                  target="__blank"
                  className="hover:text-primary/60 transition-all"
                >
                  <FiYoutube />
                </a>
              </div>
              <div>
                <p className="uppercase mt-1 lg:mt-20 text-center lg:text-justify tracking-widest text-xs font-semibold">
                  ©{new Date().getFullYear()} project sufuri.&nbsp;
                </p>
              </div>
            </div>
          </div>
          <div className="justify-start lg:justify-end flex">
            <ContactModal />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppFooter;
