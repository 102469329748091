/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { Parallax } from 'react-parallax';

const PatchworkItem = ({ patchwork, reverseOrder }) => {
  useEffect(() => {
    console.log('this', patchwork);
  }, [patchwork]);

  return (
    <div className="grid  grid-cols-1  gap-4 md:gap-0 md:grid-cols-2">
      <div
        className={`${
          reverseOrder ? 'order-2 ' : ''
        } flex  flex-col sm:flex-row items-center mr-[1px]`}
      >
        <div className="flex justify-start w-full mx-auto items-center">
          <a
            href={`/patchwork/${patchwork.id}`}
            className="block w-full cursor-pointer"
          >
            <div className="p-6"></div>
            <Parallax
              bgClassName="object-cover w-full"
              bgImageSizes="cover"
              bgImage={`${patchwork.attributes.image.data.attributes.url}`}
              strength={500}
            >
              <div className="h-[500px]"></div>
            </Parallax>
          </a>
        </div>
      </div>
      <div className="flex flex-col gap-6 justify-center">
        <div className={`flex ${reverseOrder ? 'flex-row-reverse' : ''}`}>
          <div className="w-full hidden md:flex justify-center items-center">
            <div
              data-aos="flip-down"
              className="w-full bg-primary h-[1px]"
            ></div>
          </div>
          <div
            className="border-[0.3px] border-primary p-2  w-36 text-center"
            data-aos="flip-down"
          >
            <a
              href={`/patchwork/${patchwork.id}`}
              className="text-primary text-sm uppercase"
              data-aos="fade-up"
            >
              {patchwork.attributes.date}
            </a>
          </div>
        </div>
        <div className={`flex ${reverseOrder ? 'flex-row-reverse' : ''}`}>
          <div className="w-full hidden md:flex  justify-center items-center">
            <div
              data-aos="flip-down"
              className="w-full bg-primary h-[1px]"
            ></div>
          </div>
          <div
            className="border-[0.3px] border-primary p-4 space-y-5 w-full md:[400px]"
            data-aos="flip-down"
          >
            <a
              href={`/patchwork/${patchwork.id}`}
              data-aos="fade-up"
              className="tracking-wider text-xl text-primary w-full font-alternative"
            >
              {patchwork.attributes.title}
            </a>

            <p
              data-aos="fade-up"
              className="tracking-wide leading-4 text-xs text-white"
            >
              {patchwork.attributes.excerpt}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatchworkItem;
