import React from 'react';
import { Parallax } from 'react-parallax';
import HomeEventsSection from './HomeEventsSection';
import HomeMissionSection from './HomeMissionSection';
import HomePatchworkSection from './HomePatchworkSection';

const HomeWorksWrapper = () => {
  return (
    <div>
      <Parallax
        bgImageSizes="cover"
        bgClassName="object-cover w-full opacity-95"
        bgImage="/images/potraits/vision-1.jpeg"
        strength={300}
      >
        <HomeMissionSection />
      </Parallax>
      <Parallax
        bgImageSizes="cover"
        bgClassName="object-cover w-full"
        bgImage="/images/potraits/tailormate-bg.jpg"
        strength={300}
      >
        <HomeEventsSection />
      </Parallax>
      <Parallax
        bgClassName="object-cover w-full opacity-80"
        bgImageSizes="cover"
        bgImage="/images/potraits/events-1.jpg"
        strength={300}
      >
        <HomePatchworkSection />
      </Parallax>
    </div>
  );
};

export default HomeWorksWrapper;
