import React from 'react';
import { Parallax } from 'react-parallax';

const TeamMember = ({ member, isTopLayout }) => {
  const isTopLayoutStyles = isTopLayout
    ? 'flex flex-col lg:flex-col-reverse'
    : 'flex flex-col';

  return (
    <div className={isTopLayoutStyles}>
      <Parallax
        bgClassName="object-cover bg-center w-full"
        bgImageSizes="cover"
        bgImage={`${member.attributes.image.data.attributes.url}`}
        strength={300}
      >
        <div className="h-[600px]"></div>
      </Parallax>
      <div className="border-t text-primary pt-12 space-y-1 pb-5 border-primary/70 border-b">
        <p
          data-aos="fade-up"
          className="tracking-widest uppercase font-semibold text-sm"
        >
          {member.attributes.names}
        </p>
        <p className="tracking-widest uppercase text-xs text-white">
          — {member.attributes.title}
        </p>
      </div>
    </div>
  );
};

export default TeamMember;
