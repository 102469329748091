import { DatePicker, Modal, message } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { GiBroadheadArrow } from 'react-icons/gi';
import { RiLoader5Line } from 'react-icons/ri';
import { PopoverPicker } from './PopoverPicker';
import translations from '../helpers/transalations';
import Select from 'react-select';

import TopIcon from './Icons/TopIcon';
import DressIcon from './Icons/DressIcon';
import OutwearIcon from './Icons/OutwearIcon';
import BottomsIcon from './Icons/BottomsIcon';
import SuitIcon from './Icons/SuitIcon';
import OtherClothIcon from './Icons/OtherClothIcon';

const clothCategories = ['tops', 'dresses', 'outwear', 'bottoms', 'suit'];

const clothTypeSelectData = [
  {
    value: 'tops',
    icon: <TopIcon />,
    label: (
      <div className="flex items-center uppercase font-sans gap-4">
        <div>
          <TopIcon />
        </div>
        <span>Tops</span>
      </div>
    ),
  },
  {
    value: 'dresses',
    icon: <DressIcon />,
    label: (
      <div className="flex items-center uppercase font-sans gap-4">
        <div>
          <DressIcon />
        </div>
        <span>dresses</span>
      </div>
    ),
  },
  {
    value: 'outwear',
    icon: <OutwearIcon />,
    label: (
      <div className="flex items-center uppercase font-sans gap-4">
        <div>
          <OutwearIcon />
        </div>
        <span>outwear</span>
      </div>
    ),
  },
  {
    value: 'bottoms',
    icon: <BottomsIcon />,
    label: (
      <div className="flex items-center uppercase font-sans gap-4">
        <div>
          <BottomsIcon />
        </div>
        <span>bottoms</span>
      </div>
    ),
  },
  {
    value: 'suit',
    icon: <SuitIcon />,
    label: (
      <div className="flex items-center uppercase font-sans gap-4">
        <div>
          <SuitIcon />
        </div>
        <span>suit</span>
      </div>
    ),
  },
  {
    value: 'other',
    icon: <OtherClothIcon />,
    label: (
      <div className="flex items-center uppercase font-sans gap-4">
        <div>
          <OtherClothIcon />
        </div>
        <span>other</span>
      </div>
    ),
  },
];
const fabrics = [
  'Cotton',
  'Silk',
  'Wool',
  'Linen',
  'Polyester',
  'Denim',
  'Velvet',
  'Lace',
  'Leather',
  'Igitenge',
  'other',
];

const subCategories = {
  tops: ['t-shirt', 'button-down', 'tank top', 'crop top', 'blouse', 'polo'],
  dresses: [
    'Mini dress',
    'Midi dress',
    'Maxi dress',
    'A-line dress',
    'Wrap dress',
  ],
  bottoms: ['Pants', 'Shorts', 'Jeans', 'Skirt'],
  outwear: ['Jacket', 'Coat', 'Blazer', 'Sweater', 'Cardigan', 'Hoodie'],
  suit: ['suit'],
};

const TailorMateWrapper = () => {
  const [clothCategory, setClothCategory] = useState(clothTypeSelectData[0]);
  const [clothSubCategories, setClothSubCategories] = useState([]);
  const [fabricType, setFabricType] = useState(fabrics[0]);
  const [selectedMeasurementOption, setSelectedMeasurementOption] =
    useState('no');
  const [language, setLanguage] = useState('english');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [otherFabric, setOtherFabric] = useState('');
  const [fabricColor, setFabricColor] = useState('#eebe68');
  const [clothSubCategory, setClothSubCategory] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [budget, setBudget] = useState('');
  const [moreInfo, setMoreInfo] = useState('');
  const [isLoading, setIsloading] = useState(false);
  const [isSuccessfulSubmited, setIsSuccessfulSubmited] = useState(false);
  const [isModalopen, setIsModalOpen] = useState(true);
  const [isTailorMateFormOpen, setIsTailorMateFormOpen] = useState(false);
  const [selectedPeriodTime, setSelectedPeriodTime] = useState('duedate');

  const [measurements, setMeasurements] = useState({
    bust: '',
    waist: '',
    hip: '',
    inseam: '',
    shoulder: '',
    arm: '',
    thigh: '',
    calf: '',
    neck: '',
  });

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const handleMeasurementsChange = (event) => {
    const { name, value } = event.target;
    setMeasurements((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleClothCategoryChange = (selectedOption) => {
    setClothCategory(selectedOption);
    setClothSubCategories(subCategories[selectedOption.value] ?? ['suit']);
  };

  const onDateChange = (date, dateString) => {
    setDueDate(dateString);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsSuccessfulSubmited(false);
    setIsloading(true);

    const emailMsg = `
    
    TailorMate Order \n

    Names : ${name} \n
    Email :  ${email} \n
    Phone :  ${phone} \n
    Type of Clothing :  ${clothCategory.value} \n
    Cloth Sub-Category :  ${clothSubCategory} \n
    Measurements :  ${
      selectedMeasurementOption === 'no'
        ? 'No Measurements Provided'
        : `
          Bust : ${measurements.bust} \n
          Waist : ${measurements.waist} \n
          Hip : ${measurements.hip} \n
          Inseam : ${measurements.inseam} \n
          Shoulder : ${measurements.shoulder} \n
          Arm : ${measurements.arm} \n
          Thigh : ${measurements.thigh} \n
          Calf : ${measurements.calf} \n
          Neck : ${measurements.neck} \n
      `
    } \n

    Fabric: ${otherFabric ? otherFabric : fabricType} \n
    Fabric Color: ${fabricColor} \n
    Due Date: ${dueDate} \n
    Additional Info: ${moreInfo}
    
    `;
    // axios({
    //   method: 'post',
    //   url: process.env.REACT_APP_MAILGUN_URL,
    //   auth: {
    //     username: 'api',
    //     password: process.env.REACT_APP_MAILGUN_API_KEY,
    //   },
    //   headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //   },
    //   data: {
    //     from: process.env.REACT_APP_MAILGUN_FROM_EMAIL,
    //     to: process.env.REACT_APP_MAILGUN_TO_EMAIL,
    //     subject: 'TailorMate Application',
    //     text: emailMsg,
    //   },
    // })
    //   .then((response) => {
    //     message.success(
    //       `Congrats! You've just submitted your order!\n A representative will be in contact with you to confirm your order details and begin production`
    //     );
    //     setIsSuccessfulSubmited(true);
    //   })
    //   .catch((error) => {
    //     message.error('Unexpected error occured, try again');
    //     console.log(error);
    //   })
    //   .finally(() => {
    //     setIsloading(false);
    //   });
    setIsSuccessfulSubmited(true);
  };

  const resetForm = () => {
    setClothCategory('');
    setClothSubCategories([]);
    setFabricType(fabrics[0]);
    setSelectedMeasurementOption('no');
    setLanguage('english');
    setName('');
    setEmail('');
    setPhone('');
    setOtherFabric('');
    setFabricColor('#eebe68');
    setClothSubCategory('');
    setDueDate('');
    setBudget('');
    setMoreInfo('');
    setIsSuccessfulSubmited(false);
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      zIndex: '9999 !important',
    }),
  };

  return (
    <>
      <Modal
        title="Sufuri Tailormate"
        wrapClassName="bg-black/80  uppercase font-sans"
        closable={false}
        open={isModalopen}
        footer={[
          <button
            onClick={() => setIsModalOpen(false)}
            className="py-2 px-4 text-black uppercase text-sm font-sans  font-semibold bg-primary"
          >
            Choose
          </button>,
        ]}
      >
        <div className="p-3">
          <div className="py-5 mb-10">
            <p className="text-sm leading-6 normal-case">
              {translations[language].introcontent}
            </p>
          </div>

          <div className="relative mb-6  z-0 w-full group">
            <select
              required
              value={language}
              onChange={handleLanguageChange}
              styles={customStyles}
              className="block py-2.5 px-0 w-full z-50  text-xs text-gray-800 bg-transparent border-0 border-b-2 border-gray-500    focus:outline-none focus:ring-0 uppercase   focus:border-primary peer"
            >
              <option value="" selected disabled>
                {translations[language].selectLanguage}
              </option>
              {Object.keys(translations).map((key) => (
                <option key={key} value={key}>
                  {key}
                </option>
              ))}
            </select>
            <label
              htmlFor="phone"
              className="peer-focus:font-medium font-sans  absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 uppercase tracking-widest"
            >
              {translations[language].language}
            </label>
          </div>
        </div>
      </Modal>

      <Modal
        footer={null}
        open={isTailorMateFormOpen}
        onCancel={() => setIsTailorMateFormOpen(false)}
      >
        {isSuccessfulSubmited ? (
          <div
            data-aos="fade-up"
            className="max-w-md mx-auto py-12 mt-6 text-center"
          >
            <h3 className="text-primary tracking-widest font-alternative font-bold">
              Submitted Successfully
            </h3>

            <div className="w-full h-40 my-16 flex items-center justify-center">
              <div className="h-full w-52 ">
                {React.cloneElement(clothCategory.icon, {
                  className: 'h-full w-full',
                  fill: fabricColor,
                  stroke: fabricColor,
                })}
              </div>
            </div>

            <div className="py-3 text-gray-800 uppercase text-sm space-y-2">
              {budget ? (
                <p>
                  Budget:{' '}
                  {budget.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} RWF{' '}
                </p>
              ) : (
                <></>
              )}
              {dueDate ? <p>Due date: {dueDate}</p> : <></>}
            </div>
            <p className="text-gray-800 text-sm py-3">
              Congrats! You've just submitted your order! A representative will
              be in contact with you to confirm your order details and begin
              production.
            </p>

            <button
              onClick={resetForm}
              className="text-gray-800  bg-primary/90 hover:bg-primary/50 disabled:bg-primary/40 disabled:text-gray-400 focus:ring-4 focus:outline-none focus:ring-[#050708]/50 font-semibold rounded-full text-sm px-5 py-2.5 text-center uppercase tracking-wider"
            >
              Submit Again!
            </button>
          </div>
        ) : (
          <form
            onSubmit={onSubmit}
            className="max-w-full px-2 md:max-w-xl py-8 mb-10 mx-auto"
          >
            <div className="flex flex-col md:flex-row items-center gap-3">
              <div className="relative z-0 mb-6 w-full group">
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  name="name"
                  required
                  id="name"
                  className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-500 appearance-none   focus:outline-none focus:ring-0 focus:border-primary peer"
                  placeholder=" "
                />
                <label
                  htmlFor="name"
                  className="peer-focus:font-medium font-sans absolute text-sm text-gray-800 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 uppercase tracking-widest"
                >
                  {translations[language].name}
                </label>
              </div>
              <div className="relative z-0 mb-6 w-full group">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  name="email"
                  id="email"
                  className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-500 appearance-none   focus:outline-none focus:ring-0 focus:border-primary peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="email"
                  className="peer-focus:font-medium font-sans absolute text-sm text-gray-800 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 uppercase tracking-widest"
                >
                  {translations[language].email}
                </label>
              </div>
            </div>
            <div className="relative z-0 mb-6 w-full group">
              <input
                type="tel"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                id="phone"
                className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-500 appearance-none   focus:outline-none focus:ring-0 focus:border-primary peer"
                placeholder=" "
                required
              />
              <label
                htmlFor="phone"
                className="peer-focus:font-medium font-sans absolute text-sm text-gray-800 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 uppercase tracking-widest"
              >
                {translations[language].phone}
              </label>
            </div>
            <div className="mb-6 flex flex-col md:flex-row items-center gap-3">
              <div className="relative z-0 w-full group">
                <Select
                  placeholder="Select Option"
                  value={clothCategory}
                  options={clothTypeSelectData}
                  className="block py-2.5 z-10 px-0 w-full text-xs text-gray-500 bg-transparent border-0  border-gray-500 focus:outline-none focus:ring-0 uppercase font-bn peer"
                  onChange={handleClothCategoryChange}
                />
                <label
                  htmlFor="clothCategory"
                  className="peer-focus:font-medium font-sans absolute text-sm text-gray-800 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 uppercase tracking-widest"
                >
                  {translations[language].clothType}
                </label>
              </div>
              {clothCategory.value === 'other' ? (
                <div className="relative z-0 mb-2 w-full group">
                  <input
                    type="text"
                    name="other-clothCategory"
                    id="other-clothCategory"
                    className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-500 appearance-none   focus:outline-none focus:ring-0 focus:border-primary peer"
                    placeholder=" "
                    required
                  />
                  <label
                    htmlFor="other-clothCategory"
                    className="peer-focus:font-medium font-sans absolute text-sm text-gray-800 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 uppercase tracking-widest"
                  >
                    Other
                  </label>
                </div>
              ) : (
                <div className="relative !z-0  w-full group">
                  <select
                    value={clothSubCategory}
                    onChange={(e) => setClothSubCategory(e.target.value)}
                    className="block py-2.5 px-0 w-full text-xs text-gray-500 bg-transparent border-0 border-b-2 border-gray-500    focus:outline-none focus:ring-0 uppercase font-bn   focus:border-primary peer"
                  >
                    <option value="" selected disabled>
                      Select cloth sub-category
                    </option>
                    {clothSubCategories.map((clothType) => (
                      <option key={clothType} value={clothType}>
                        {clothType}
                      </option>
                    ))}
                  </select>
                  <label
                    htmlFor="clothType"
                    className="peer-focus:font-medium font-sans absolute text-sm text-gray-800 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 uppercase tracking-widest"
                  >
                    {translations[language].clothSubType}
                  </label>
                </div>
              )}
            </div>

            <div className="mb-6  mt-32  border-b border-dotted border-b-primary/50">
              <h4 className="font-bn text-sm uppercase">
                {' '}
                {translations[language].measurements}
              </h4>
              <div className="my-4">
                <p className="text-sm mb-2 uppercase font-medium text-gray-800">
                  {' '}
                  {translations[language].measurementsDescription}
                </p>
                <div className="flex gap-2">
                  <div className="flex gap-1 mt-3 mb-5 items-center">
                    <input
                      type="radio"
                      name="measurements-approve"
                      value="no"
                      checked={selectedMeasurementOption === 'no'}
                      onChange={(e) =>
                        setSelectedMeasurementOption(e.target.value)
                      }
                      id="measurements-notapproved"
                      className="w-5 h-5 !bg-gray-700  border-gray-700 rounded-lg !focus:ring-yellow-600"
                    />
                    <label
                      htmlFor="measurements-notapproved"
                      className="ml-1 text-sm uppercase font-medium text-gray-700"
                    >
                      {translations[language]['measurements-answer-1']}
                    </label>
                  </div>
                  <div className="flex gap-1 mt-3 mb-5 items-center">
                    <input
                      type="radio"
                      name="measurements-approve"
                      value="yes"
                      checked={selectedMeasurementOption === 'yes'}
                      onChange={(e) =>
                        setSelectedMeasurementOption(e.target.value)
                      }
                      id="measurements-approved"
                      className="w-5 h-5 !bg-gray-700 !text-yellow-400 border-gray-700 rounded-lg !focus:ring-yellow-600"
                    />
                    <label
                      htmlFor="measurements-approved"
                      className="ml-1 text-sm uppercase font-medium text-gray-700"
                    >
                      {translations[language]['measurements-answer-2']}
                    </label>
                  </div>
                </div>
              </div>

              {selectedMeasurementOption === 'yes' &&
              clothCategory.value !== 'suit' &&
              clothCategory.value !== 'other' &&
              clothCategory.value !== 'outwear' ? (
                <div>
                  <div className="grid my-8 grid-cols-1 md:grid-cols-3 gap-3">
                    <div className="relative z-0 mb-8 w-full group">
                      <input
                        type="number"
                        name="bust"
                        value={measurements.bust}
                        onChange={handleMeasurementsChange}
                        id="bust"
                        className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-500 appearance-none   focus:outline-none focus:ring-0 focus:border-primary peer"
                        placeholder=" "
                      />
                      <label
                        htmlFor="bust"
                        className="peer-focus:font-medium font-sans absolute text-sm text-gray-800 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 uppercase tracking-widest"
                      >
                        {translations[language].measurementsList.bust} (CM)
                      </label>
                    </div>

                    {clothCategory.value === 'tops' ||
                    clothCategory.value === 'dresses' ||
                    clothCategory.value === 'bottoms' ? (
                      <div className="relative z-0 mb-8 w-full group">
                        <input
                          type="number"
                          name="waist"
                          id="waist"
                          value={measurements.waist}
                          onChange={handleMeasurementsChange}
                          className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-500 appearance-none   focus:outline-none focus:ring-0 focus:border-primary peer"
                          placeholder=" "
                        />
                        <label
                          htmlFor="waist"
                          className="peer-focus:font-medium font-sans absolute text-sm text-gray-800 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 uppercase tracking-widest"
                        >
                          {translations[language].measurementsList.waist}(CM)
                        </label>
                      </div>
                    ) : (
                      <></>
                    )}
                    {clothCategory.value === 'tops' ||
                    clothCategory.value === 'dresses' ||
                    clothCategory.value === 'bottoms' ? (
                      <div className="relative z-0 mb-8 w-full group">
                        <input
                          type="number"
                          name="hip"
                          id="hip"
                          value={measurements.hip}
                          onChange={handleMeasurementsChange}
                          className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-500 appearance-none   focus:outline-none focus:ring-0 focus:border-primary peer"
                          placeholder=" "
                        />
                        <label
                          htmlFor="hip"
                          className="peer-focus:font-medium font-sans absolute text-sm text-gray-800 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 uppercase tracking-widest"
                        >
                          {translations[language].measurementsList.hip}(CM)
                        </label>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="grid my-8 grid-cols-1 md:grid-cols-3 gap-3">
                    {clothCategory.value === 'bottoms' ? (
                      <div className="relative z-0 mb-8 w-full group">
                        <input
                          type="number"
                          name="inseam"
                          id="inseam"
                          value={measurements.inseam}
                          onChange={handleMeasurementsChange}
                          className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-500 appearance-none   focus:outline-none focus:ring-0 focus:border-primary peer"
                          placeholder=" "
                        />
                        <label
                          htmlFor="inseam"
                          className="peer-focus:font-medium font-sans absolute text-sm text-gray-800 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 uppercase tracking-widest"
                        >
                          {translations[language].measurementsList.inseam}(CM)
                        </label>
                      </div>
                    ) : (
                      <></>
                    )}
                    {clothCategory.value === 'tops' ||
                    clothCategory.value === 'dresses' ? (
                      <div className="relative z-0 mb-8 w-full group">
                        <input
                          type="number"
                          name="shoulder"
                          id="shoulder"
                          value={measurements.shoulder}
                          onChange={handleMeasurementsChange}
                          className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-500 appearance-none   focus:outline-none focus:ring-0 focus:border-primary peer"
                          placeholder=" "
                        />
                        <label
                          htmlFor="shoulder"
                          className="peer-focus:font-medium font-sans absolute text-sm text-gray-800 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 uppercase tracking-widest"
                        >
                          {translations[language].measurementsList.shoulder}(CM)
                        </label>
                      </div>
                    ) : (
                      <></>
                    )}
                    {clothCategory.value === 'tops' ||
                    clothCategory.value === 'dresses' ? (
                      <div className="relative z-0 mb-8 w-full group">
                        <input
                          type="number"
                          name="arm"
                          id="arm"
                          value={measurements.arm}
                          onChange={handleMeasurementsChange}
                          className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-500 appearance-none   focus:outline-none focus:ring-0 focus:border-primary peer"
                          placeholder=" "
                        />
                        <label
                          htmlFor="arm"
                          className="peer-focus:font-medium font-sans absolute text-sm text-gray-800 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 uppercase tracking-widest"
                        >
                          {translations[language].measurementsList.arm}(CM)
                        </label>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="grid my-8 grid-cols-1 md:grid-cols-3 gap-3">
                    {clothCategory.value === 'bottoms' ? (
                      <div className="relative z-0 mb-8 w-full group">
                        <input
                          type="number"
                          name="thigh"
                          id="thigh"
                          value={measurements.thigh}
                          onChange={handleMeasurementsChange}
                          className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-500 appearance-none   focus:outline-none focus:ring-0 focus:border-primary peer"
                          placeholder=" "
                        />
                        <label
                          htmlFor="thigh"
                          className="peer-focus:font-medium font-sans absolute text-sm text-gray-800 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 uppercase tracking-widest"
                        >
                          {translations[language].measurementsList.thigh}(CM)
                        </label>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="relative z-0 mb-8 w-full group">
                      <input
                        type="number"
                        name="calf"
                        id="calf"
                        value={measurements.calf}
                        onChange={handleMeasurementsChange}
                        className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-500 appearance-none   focus:outline-none focus:ring-0 focus:border-primary peer"
                        placeholder=" "
                      />
                      <label
                        htmlFor="calf"
                        className="peer-focus:font-medium font-sans absolute text-sm text-gray-800 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 uppercase tracking-widest"
                      >
                        {translations[language].measurementsList.calf}(CM)
                      </label>
                    </div>
                    {clothCategory.value === 'tops' ? (
                      <div className="relative z-0 mb-8 w-full group">
                        <input
                          type="number"
                          name="neck"
                          id="neck"
                          value={measurements.neck}
                          onChange={handleMeasurementsChange}
                          className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-500 appearance-none   focus:outline-none focus:ring-0 focus:border-primary peer"
                          placeholder=" "
                        />
                        <label
                          htmlFor="neck"
                          className="peer-focus:font-medium font-sans absolute text-sm text-gray-800 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 uppercase tracking-widest"
                        >
                          {translations[language].measurementsList.neck}(CM)
                        </label>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="mb-6 mt-24 flex flex-col md:flex-row items-center gap-3">
              <div className="relative z-0 w-full group">
                <select
                  value={fabricType}
                  onChange={(e) => setFabricType(e.target.value)}
                  className="block py-2.5 px-0 w-full text-xs text-gray-500 bg-transparent border-0 border-b-2 border-gray-500    focus:outline-none focus:ring-0 uppercase font-bn focus:border-primary peer"
                >
                  {fabrics.map((fabric) => (
                    <option key={fabric} value={fabric}>
                      {fabric}
                    </option>
                  ))}
                </select>
                <label
                  htmlFor="fabricType"
                  className="peer-focus:font-medium font-sans absolute text-sm text-gray-800 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 uppercase tracking-widest"
                >
                  {translations[language].fabrics}
                </label>
              </div>
              {fabricType === 'other' ? (
                <div className="relative z-0 mb-2 w-full group">
                  <input
                    type="text"
                    value={otherFabric}
                    onChange={(e) => setOtherFabric(e.target.value)}
                    name="other-fabric"
                    id="other-fabric"
                    className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-500 appearance-none   focus:outline-none focus:ring-0 focus:border-primary peer"
                    placeholder=" "
                    required
                  />
                  <label
                    htmlFor="other-fabric"
                    className="peer-focus:font-medium font-sans absolute text-sm text-gray-800 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 uppercase tracking-widest"
                  >
                    {translations[language].otherFabrics}
                  </label>
                </div>
              ) : (
                <></>
              )}
              <div className="relative  z-10 w-full group">
                <PopoverPicker color={fabricColor} onChange={setFabricColor} />

                <label
                  htmlFor="fabric-color"
                  className="peer-focus:font-medium font-sans absolute text-sm text-gray-800 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 uppercase tracking-widest"
                >
                  {translations[language].fabricColor}
                </label>
              </div>
            </div>

            <div className="py-5 mb-14">
              <label className="text-gray-800 mb-3 flex gap-6 items-center  text-sm uppercase font-bn">
                <div className="flex gap-2 items-center">
                  <input
                    id="duedate-option"
                    type="radio"
                    name="default-radio"
                    value="duedate"
                    checked={selectedPeriodTime === 'duedate'}
                    onChange={(e) => setSelectedPeriodTime(e.target.value)}
                    className="w-5 h-5 !bg-gray-700 !text-yellow-400 border-gray-700 rounded-lg !focus:ring-yellow-600"
                  />
                  <label
                    htmlFor="duedate-option"
                    className="text-sm font-medium text-gray-800 font-sans"
                  >
                    {translations[language].chooseDate}
                  </label>
                </div>
                <div className="flex gap-2 items-center">
                  <input
                    id="duedate-option2"
                    type="radio"
                    value="flexible"
                    checked={selectedPeriodTime === 'flexible'}
                    onChange={(e) => setSelectedPeriodTime(e.target.value)}
                    name="default-radio"
                    className="w-5 h-5 !bg-gray-700 !text-yellow-400 border-gray-700 rounded-lg !focus:ring-yellow-600"
                  />
                  <label
                    htmlFor="duedate-option2"
                    className="ml-2 text-sm font-medium text-gray-800 font-sans"
                  >
                    {translations[language].flexible}
                  </label>
                </div>
              </label>
              {selectedPeriodTime === 'duedate' ? (
                <DatePicker
                  style={{ zIndex: -1 }}
                  menuPosition={'relative'}
                  placeholder={translations[language].dueDate}
                  className="w-full bg-gray-200 rounded-none !z-0 border-none"
                  onChange={onDateChange}
                />
              ) : (
                <></>
              )}
            </div>

            <div className="relative z-0 mb-2 w-full group">
              <input
                type="number"
                value={budget}
                onChange={(e) => setBudget(e.target.value)}
                name="budget"
                id="budget"
                className="block  py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-500 appearance-none   focus:outline-none focus:ring-0 focus:border-primary peer"
                placeholder=" "
              />
              <label
                htmlFor="budget"
                className="peer-focus:font-medium -z-10 font-sans absolute text-sm text-gray-800 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 uppercase tracking-widest"
              >
                {translations[language].budget} (RWF)
              </label>
            </div>
            <div className="mb-6">
              <small className="text-xs -z-10 text-gray-500">
                {translations[language].budgetDescription}
              </small>
            </div>

            <div className="relative mt-12 z-0 w-full group">
              <textarea
                value={moreInfo}
                onChange={(e) => setMoreInfo(e.target.value)}
                className="block pt-10 py-2.5 px-0 w-full text-xs text-gray-800 bg-transparent border-0 border-b-2 border-gray-500    focus:outline-none focus:ring-0 uppercase font-bn   focus:border-primary peer"
              ></textarea>
              <label
                htmlFor="phone"
                className="peer-focus:font-medium font-sans  absolute text-sm text-gray-800 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 uppercase tracking-widest"
              >
                {translations[language].moreInfo}
              </label>
            </div>
            <div className="mb-6">
              <small className="text-xs -z-10 text-gray-500">
                {translations[language].moreInfoSubText}
              </small>
            </div>
            <button
              type="submit"
              disabled={isLoading}
              className="text-gray-800 w-full  bg-primary/90 hover:bg-primary/50 disabled:bg-primary/40 disabled:text-gray-400 focus:ring-4 focus:outline-none focus:ring-[#050708]/50 font-semibold rounded-full text-sm px-5 py-2.5 text-center inline-flex justify-between uppercase tracking-wider  items-center mr-2 mb-2"
            >
              {translations[language].submit}
              <span className={`text-xl  ${isLoading ? 'animate-spin' : ''} `}>
                {isLoading ? <RiLoader5Line /> : <GiBroadheadArrow />}
              </span>
            </button>
          </form>
        )}
      </Modal>

      <div className="bg-[#1C201F]">
        <div className="container mx-auto px-2  py-10">
          <div className="grid gap-3 grid-cols-1 md:grid-cols-9">
            <div className="min-h-[300px] flex flex-col justify-around text-center">
              <div className="flex items-center justify-center">
                <img
                  src="/images/icons/icon-form-1.jpg"
                  className="w-[100px]"
                  alt=""
                />
              </div>
              <h3 className="text-lg text-primary uppercase">Consultation</h3>
              <p className="text-sm">
                Meet with Sufuri team to discuss your design preferences and
                place your bespoke custom order using our digital order
                placement form.
              </p>
            </div>
            <div className="border-t hidden md:block w-full border-dashed place-self-center border-primary  h-1 " />
            <div className="min-h-[300px] flex flex-col justify-around  text-center">
              <div className="flex items-center justify-center">
                <img
                  src="/images/icons/icon-form-2.jpg"
                  className="w-[90px]"
                  alt=""
                />
              </div>
              <h3 className="text-lg text-primary uppercase">Details</h3>
              <p className="text-sm">
                Provide your measurements, contact information and availability
                for a follow-up appointment
              </p>
            </div>
            <div className="border-t hidden md:block w-full border-dashed place-self-center border-primary  h-1 " />
            <div className="min-h-[300px] flex flex-col justify-around  text-center">
              <div className="flex items-center justify-center">
                <img
                  src="/images/icons/icon-form-3.jpg"
                  className="w-[70px]"
                  alt=""
                />
              </div>
              <h3 className="text-lg text-primary uppercase">confirmation</h3>
              <p className="text-sm">
                Verify and confirm your order details with an optional 25%
                deposit to Kuza Africa.
              </p>
            </div>
            <div className="border-t hidden md:block w-full border-dashed place-self-center border-primary  h-1 " />
            <div className="min-h-[300px] flex flex-col justify-around  text-center">
              <div className="flex items-center justify-center">
                <img
                  src="/images/icons/icon-form-4.jpg"
                  className="w-[70px]"
                  alt=""
                />
              </div>
              <h3 className="text-lg text-primary uppercase">Follow-up</h3>
              <p className="text-sm">
                Kuza Africa's team will follow up to confirm the order details
                the day after the Bazaar and collect the remaining deposit to
                total 50% of the full amount.
              </p>
            </div>
            <div className="border-t hidden md:block w-full border-dashed place-self-center border-primary  h-1 " />
            <div className="min-h-[300px] flex flex-col justify-around  text-center">
              <div className="flex items-center justify-center">
                <img
                  src="/images/icons/icon-form-5.jpg"
                  className="w-[70px]"
                  alt=""
                />
              </div>
              <h3 className="text-lg text-primary uppercase">Payment</h3>
              <p className="text-sm">
                Receive your custom order by submitting the remaining 50%
                balance upon pickup or delivery.
              </p>
            </div>
          </div>

          <div className="py-7 mt-28">
            <h3 className="text-2xl font-semibold text-primary font-alternative mb-4">
              Partners
            </h3>
            <img alt="" src="/images/kuza_logo.png" className="w-40" />
          </div>
          <div>
            <button
              onClick={() => setIsTailorMateFormOpen(true)}
              className="py-4 px-5 text-black uppercase text-sm font-sans  font-semibold bg-primary hover:bg-primary/80 transition-all"
            >
              Place an order
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TailorMateWrapper;
