/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { IoLogoTiktok } from 'react-icons/io5';
import { GiBroadheadArrow } from 'react-icons/gi';
import { FiYoutube, FiTwitter, FiInstagram } from 'react-icons/fi';

export default function ContactModal() {
  let [isOpen, setIsOpen] = useState(false);
  let [subject, setSubject] = useState('');
  let [email, setEmail] = useState('');
  let [message, setMessage] = useState('');

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const openEmailComposer = () => {
    window.open(`mailto:${email}?subject=${subject}&body=${message}`);
  };

  return (
    <>
      <p
        onClick={openModal}
        className="uppercase cursor-pointer tracking-wider text-sm font-semibold hover:text-primary/70"
      >
        contact us
      </p>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-80" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-xl bg-[#111] p-8 text-left align-middle shadow-2xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl mb-5 font-medium leading-6 text-primary font-bn uppercase text-center"
                  >
                    Connect With Us
                  </Dialog.Title>
                  <div className="py-9 flex flex-col-reverse lg:flex-row gap-4 text-primary">
                    <div className="space-y-3 w-full">
                      <h4 className="font-semibold text-lg font-bn uppercase">
                        Contact Info
                      </h4>
                      <div className="space-y-6">
                        <p className="tracking-widest">info@codity</p>
                        <div className="flex space-x-4">
                          <a
                            href="https://twitter.com/ProjectSufuri"
                            target="__blank"
                            className="hover:text-primary/60 transition-all"
                          >
                            <FiTwitter />
                          </a>
                          <a
                            href="https://www.instagram.com/project.sufuri"
                            target="__blank"
                            className="hover:text-primary/60 transition-all"
                          >
                            <FiInstagram />
                          </a>
                          <a
                            href="https://www.tiktok.com/@projectsufuri"
                            target="__blank"
                            className="hover:text-primary/60 transition-all"
                          >
                            <IoLogoTiktok />
                          </a>
                          <a
                            href="https://www.youtube.com/@projectsufuri"
                            target="__blank"
                            className="hover:text-primary/60 transition-all"
                          >
                            <FiYoutube />
                          </a>
                        </div>
                      </div>
                    </div>
                    <form onSubmit={openEmailComposer} className="w-full">
                      <div class="relative z-0 mb-6 w-full group">
                        <input
                          type="email"
                          name="floating_email"
                          id="floating_email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gray-500 appearance-none   focus:outline-none focus:ring-0 focus:border-primary peer"
                          placeholder=" "
                          required
                        />
                        <label
                          htmlFor="floating_email"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 uppercase tracking-widest"
                        >
                          Your email
                        </label>
                      </div>
                      <div class="relative z-0 mb-6 w-full group">
                        <input
                          type="text"
                          name="floating_name"
                          id="floating_name"
                          className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gray-500 appearance-none   focus:outline-none focus:ring-0 focus:border-primary peer"
                          placeholder=" "
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                          required
                        />
                        <label
                          htmlFor="floating_name"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 uppercase tracking-widest"
                        >
                          Subject
                        </label>
                      </div>
                      <div class="relative z-0 mb-6 w-full group">
                        <textarea
                          type="email"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          name="floating_message"
                          id="floating_message"
                          className="block py-2.5 px-0 w-full text-sm text-white bg-transparent border-0 border-b-2 border-gray-500 appearance-none   focus:outline-none focus:ring-0 focus:border-primary peer"
                          placeholder=" "
                          required
                        ></textarea>
                        <label
                          htmlFor="floating_message"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 uppercase tracking-widest"
                        >
                          Your message
                        </label>
                      </div>
                      <button
                        type="submit"
                        className="text-white w-full bg-primary/90 hover:bg-primary/50 focus:ring-4 focus:outline-none focus:ring-[#050708]/50 font-semibold rounded-full text-sm px-5 py-2.5 text-center inline-flex justify-between uppercase tracking-wider  items-center mr-2 mb-2"
                      >
                        Send message
                        <span className="text-xl">
                          <GiBroadheadArrow />
                        </span>
                      </button>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
