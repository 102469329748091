import React from 'react';
import HomeIntroSection from '../components/HomeIntroSection';
import HomeWorksWrapper from '../components/HomeWorksWrapper';
import { Helmet } from 'react-helmet';

const HomeView = () => {
  return (
    <>
      <Helmet>
        <title>Project Sufuri</title>
      </Helmet>
      <HomeIntroSection />
      <HomeWorksWrapper />
    </>
  );
};

export default HomeView;
