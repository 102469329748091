import React from 'react';

const HomeIntroSection = () => {
  return (
    <div className="min-h-screen flex justify-center items-center relative">
      <video
        autoPlay
        loop
        muted
        className="-z-10 object-cover opacity-95  w-screen h-screen absolute"
      >
        <source src="vids/sufuri-intro-video.webm" type="video/mp4" />
      </video>

      <h1
        data-aos="fade-up"
        className="z-30 font-bold font-alternative text-5xl lg:text-8xl  text-primary"
      >
        SUFURI
      </h1>
    </div>
  );
};

export default HomeIntroSection;
