import React, { useEffect, useState } from 'react';
import { Parallax } from 'react-parallax';
import { HiArrowLongLeft, HiArrowLongRight } from 'react-icons/hi2';
import { useNavigate, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import { Triangle } from 'react-loader-spinner';

const PatchworkSingle = () => {
  let params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [patchwork, setPatchwork] = useState({});
  const [currentPage, setCurrentPage] = useState(null);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const getPatchwork = async () => {
      try {
        setIsLoading(true);
        const res = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/patchworks/${params.id}?populate=patchworkpages&populate=patchworkpages.image`
        );
        let pages = res.data.data.attributes.patchworkpages.data;
        setPatchwork({
          date: res.data.data.attributes.date,
          title: res.data.data.attributes.title,
          excerpt: res.data.data.attributes.excerpt,
          pages,
        });
      } catch (error) {
        setError(error);
        if (error.response.status === 404) {
          navigate('*');
        }
      } finally {
        setIsLoading(false);
      }
    };
    getPatchwork();
  }, [navigate, params.id]);

  useEffect(() => {
    if (patchwork.pages) {
      setCurrentPage(patchwork.pages[currentPageIndex]);
    }
  }, [currentPageIndex, patchwork.pages]);

  const prevPage = () => {
    if (currentPageIndex === 0) return;
    setCurrentPageIndex(currentPageIndex - 1);
  };
  const nextPage = () => {
    if (currentPageIndex >= patchwork.pages.length - 1) return;
    setCurrentPageIndex(currentPageIndex + 1);
  };

  return (
    <div className="min-h-screen bg-gray-900/40">
      {error && (
        <h2 className="text-center text-3xl font-bold py-8 h-screen flex items-center justify-center font-alternative text-red-500">
          SERVER ERROR , TRY AGAIN!
        </h2>
      )}

      {isLoading && (
        <div>
          <div className="flex items-center justify-center">
            <Triangle
              height="80"
              width="80"
              color="#eebe68"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}

      {currentPage && (
        <div className="flex flex-col lg:flex-row min-h-full">
          <div className="w-full lg:w-1/2 relative">
            <Parallax
              bgClassName="object-cover max-w-full  w-full opacity-95"
              bgImageSizes="cover"
              bgImage={`${currentPage.attributes.image.data.attributes.url}`}
              strength={500}
            >
              <div className="min-h-screen  max-h-full max-w-full"></div>
            </Parallax>
          </div>
          <div className="w-full lg:w-1/2  relative">
            <div className="container mx-auto space-y-5 py-10 px-10">
              {/* patchwork intro section */}
              <div>
                <h2
                  data-aos="fade-up"
                  className="font-bold text-2xl mt-4 md:text-4xl text-primary w-full  lg:w-[480px] font-alternative"
                >
                  {patchwork.title}
                </h2>
                <div className="flex flex-col md:flex-row gap-3 pt-10">
                  <h3
                    data-aos="fade-up"
                    className="text-primary/80 py-2 text-sm w-full md:w-40 font-semibold uppercase"
                  >
                    {patchwork.date}
                  </h3>
                  <div className="border-l pl-3 w-full t border-primary">
                    <p className="text-sm ">{patchwork.excerpt}</p>
                  </div>
                </div>
              </div>

              {/* patchwork pages */}
              <div>
                <div className="flex gap-3 flex-col md:flex-row pt-16">
                  <h3 className="text-primary leading-6 w-full md:w-[210px] font-bn text-xl">
                    {currentPage.attributes.title}
                  </h3>
                  <div className="w-full !max-h-[370px] primary-scrollbar relative !min-h-[370px]  overflow-x-auto patchwork-content">
                    <ReactMarkdown>
                      {currentPage.attributes.content}
                    </ReactMarkdown>
                  </div>
                </div>
              </div>
              <div className="p-3 justify-between flex">
                <div>
                  {currentPageIndex !== 0 && (
                    <button
                      onClick={prevPage}
                      className="text-3xl uppercase  flex items-center gap-1 cursor-pointer text-primary"
                    >
                      <HiArrowLongLeft />
                      <span className="text-xs tracking-wider">
                        {' '}
                        Prev page
                      </span>{' '}
                    </button>
                  )}
                </div>
                <div>
                  <a
                    href="/patchwork"
                    className="uppercase tracking-wider text-sm  font-alternative  font-semibold cursor-pointer text-primary hover:text-white transition-all"
                  >
                    all patchworks
                  </a>
                </div>
                <div>
                  {patchwork.pages.length - 1 !== currentPageIndex && (
                    <button
                      onClick={nextPage}
                      className="text-3xl uppercase  flex items-center gap-1 cursor-pointer text-primary hover:text-primary/75"
                    >
                      <span className="text-xs tracking-wider"> Next page</span>{' '}
                      <HiArrowLongRight />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PatchworkSingle;
