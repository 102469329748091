import * as React from 'react';
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={24}
    height={24}
    viewBox="0 0 296.531 296.531"
    {...props}
  >
    <path d="m168.86 151.016-22.138-37.971-22.141 37.971 22.141 37.325zM103.753 151.016l-22.139-37.97-22.138 37.97 22.138 37.324zM233.965 151.016l-22.14-37.97-22.137 37.97 22.137 37.323z" />
    <path d="M47.362 266.041c0 15.825 12.765 28.7 28.455 28.7l145.113-.001c15.57 0 28.238-12.874 28.238-28.699V157.677l-30.261 51.011a8.236 8.236 0 0 1-14.169 0l-25.465-42.936-25.469 42.936a8.237 8.237 0 0 1-14.169 0l-25.47-42.937-25.468 42.937a8.237 8.237 0 0 1-14.169 0L47.36 162.887v103.154zm134.186-37.534c4.918 0 8.909 4.199 8.909 9.373 0 5.181-3.991 9.379-8.909 9.379-4.922 0-8.912-4.198-8.912-9.379 0-5.174 3.99-9.373 8.912-9.373zm-70.014 0c4.918 0 8.908 4.199 8.908 9.373 0 5.181-3.99 9.38-8.908 9.38-4.922 0-8.913-4.199-8.913-9.38 0-5.174 3.991-9.373 8.913-9.373z" />
    <path d="M272.983 13.149 228.288 2.503c-1.991-.473-4.132-.713-6.358-.713-12.017 0-24.797 6.88-30.39 16.358-.511.867-12.873 21.23-43.28 21.23-30.255 0-42.562-20.054-43.07-20.907-5.586-9.367-18.3-16.165-30.233-16.165-2.299 0-4.506.255-6.561.757L23.726 13.99C10.864 17.137 0 30.979 0 44.218v206.11c0 12.566 10.161 22.79 22.652 22.79h8.792a45.491 45.491 0 0 1-.556-7.077V139.268a8.237 8.237 0 0 1 8.237-8.237c4.491 0 8.137 3.598 8.228 8.068l27.146-46.555a8.238 8.238 0 0 1 14.231 0l25.437 43.627 25.438-43.627a8.238 8.238 0 0 1 14.231 0l25.438 43.627 25.434-43.627a8.238 8.238 0 0 1 14.231-.001l30.229 51.841v-5.116a8.237 8.237 0 0 1 16.473 0v126.773c0 2.408-.192 4.771-.553 7.077h9.133c12.301 0 22.309-10.224 22.309-22.79V43.155c.001-13.235-10.781-26.976-23.547-30.006zM43.579 94.875c-4.922 0-8.913-4.199-8.913-9.379 0-5.175 3.991-9.374 8.913-9.374 4.918 0 8.908 4.199 8.908 9.374 0 5.18-3.99 9.379-8.908 9.379zm67.955 0c-4.922 0-8.913-4.199-8.913-9.379 0-5.175 3.991-9.374 8.913-9.374 4.918 0 8.908 4.199 8.908 9.374 0 5.18-3.99 9.379-8.908 9.379zm70.014 0c-4.922 0-8.912-4.199-8.912-9.38 0-5.174 3.99-9.373 8.912-9.373 4.918 0 8.909 4.199 8.909 9.373 0 5.181-3.991 9.38-8.909 9.38zm67.956 0c-4.922 0-8.913-4.199-8.913-9.38 0-5.174 3.991-9.373 8.913-9.373 4.917 0 8.908 4.199 8.908 9.373 0 5.181-3.991 9.38-8.908 9.38z" />
  </svg>
);
export default SvgComponent;
